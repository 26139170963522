.tax-return-status-box-container-not-completed {
    border: 1px solid var(--yellow-color-1);
    border-radius: 10px;
    min-height: 190px;
}

.tax-return-status-box-container-completed {
    background: var(--blue-color-10);
    border-radius: 10px;
    min-height: 190px;
}

.tax-return-status-box-title {
    font: normal normal 600 15px/22px Poppins;
    letter-spacing: 0.15px;
    min-height: 50px;
}

.tax-return-status-box-description {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.12px;
    min-height: 66px;
    color: var(--dark-gray-color-1);
}

.tax-return-status-box-description-completed {
    color: var(--black-color);
}

.tax-return-status-box-warn-message {
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--yellow-color-1);
}