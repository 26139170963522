.quickfisco-single-file-container {
    padding: 1.5rem 1rem;
    border: 1px solid var(--blue-color-9);
    border-radius: 10px;
}

.quickfisco-single-file-error {
    text-align: center;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.quickfisco-single-file-doc-remove {
    text-align: center;
    text-decoration: underline;
    font: normal normal medium Poppins;
    font-size: 13px;
    letter-spacing: 0.96px;
    color: var(--blue-color-2);
}

.quickfisco-single-file-doc-box {
    display: flex;
    background-color: var(--white-color);
    border: 1px solid var(--blue-color-7);
    border-radius: 10px;
    padding: 15px 5px;
    text-align: left;
    width: 100%;
    min-height: 75px;
}

.quickfisco-single-file-doc-title {
    display: flex;
    text-align: left;
    font: normal normal 600 13px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}