.tax-return-section-4-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-4-subtitle {
  text-align: left;
  font: normal normal medium Poppins;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: var(--blue-color-2);
  margin-top: 2rem;
}

.tax-return-section-4-yellow-box {
  font: normal normal bold 12px/18px Poppins;
  border: 1px solid var(--yellow-color-1);
  border-radius: 11px;
}

.tax-return-section-4-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-4-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}