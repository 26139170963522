.taxReturn-upload-multi-doc-container {
    padding: 1.5rem 1rem;
    border: 1px solid var(--blue-color-9);
    border-radius: 10px;
}

.taxReturn-upload-multi-doc-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 13px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
}

.taxReturn-upload-multi-doc-subtitle {
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
}

.taxReturn-upload-multi-doc-file-box {
    background-color: var(--blue-color-10);
    border-radius: 10px;
    padding: 1rem;
}

.tax-return-upload-multi-doc-loading {
    min-height: 54px;
}

.taxReturn-upload-multi-doc-file-name {
    font: normal normal normal 13px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    line-break: anywhere;
}

.taxReturn-upload-multi-doc-file-list-container {
    height: 190px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.taxReturn-upload-multi-doc-file-list-container::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 6px;
}

.taxReturn-upload-multi-doc-file-list-container::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.taxReturn-upload-multi-doc-no-file {
    font: normal normal normal 14px/22px Poppins;
    color: var(--dark-gray-color-3);
}

.taxReturn-upload-multi-doc-error {
    text-align: center;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}