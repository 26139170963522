.tax-return-section-intro-title {
    text-align: center;
    font: normal normal 600 20px/30px Poppins;
    letter-spacing: 0px;
}

.tax-return-section-intro-title span {
    color: var(--blue-color-2);
}

.tax-return-section-intro-icon {
    width: 3%;
}

.tax-return-section-intro-description {
    text-align: center;
    font: normal normal normal 16px/30px Poppins;
    letter-spacing: 0px;
}

.tax-return-section-intro-description span {
    color: var(--blue-color-2);
}

.tax-return-section-intro-info {
    text-align: center;
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0px;
}