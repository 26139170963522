.f24-by-QF-modal-sm .modal-content {
    width: 175%;
    left: -36%;
}

.f24-by-QF-modal-lg .modal-content {
    width: 230%;
    left: -60%;
}

.f24-by-QF-modal-title {
    font: normal normal 600 21px/31px Poppins;
    color: var(--blue-color-2);
}