.lands-buildings-accordion {
    border-radius: 15px;

}

.lands-buildings-accordion .accordion-item {
    border: 1px solid var(--blue-color-5);
    border-radius: 15px;
}

.lands-buildings-accordion .accordion-button {
    font: normal normal 600 14px/18px Poppins;
    color: var(--blue-color-2);
    border-radius: 15px !important;

}

.lands-buildings-accordion .accordion-button:focus,
.lands-buildings-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.lands-buildings-accordion .accordion-button:not(.collapsed) {
    background-color: var(--white-color);
}

.lands-buildings-accordion .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.lands-buildings-accordion .accordion-body {
    font: normal normal normal 13px/18px Poppins;
}

.lands-buildings-accordion-list li::marker {
    color: var(--dark-gray-color-1);
}

.lands-buildings-accordion .accordion-body .text-green {
    font-weight: 600;
    color: var(--green-color-1);
}

.lands-buildings-accordion .accordion-body .text-blue {
    color: var(--blue-color-2);
}