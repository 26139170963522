.tax-return-upload-info-title {
    text-align: center;
    font: normal normal 600 20px/30px Poppins;
    letter-spacing: 0px;
}

.tax-return-upload-info-title span {
    color: var(--blue-color-2);
}

.tax-return-upload-info-icon {
    width: 3%;
}

.tax-return-upload-info-description {
    text-align: center;
    font: normal normal normal 16px/30px Poppins;
    letter-spacing: 0px;
}

.tax-return-upload-info-big-text {
    text-align: center;
    font: normal normal 600 18px/30px Poppins;
    letter-spacing: 0px;
}

.tax-return-upload-info-container-box {
    background: var(--blue-color-10);
    border-radius: 11px;
    opacity: 1;
    text-align: left;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tax-return-upload-info-box-img {
    height: 35px;
}

.tax-return-upload-info-box-title {
    font: normal normal 600 17px/27px Poppins;
    min-height: 54px;
}

.tax-return-upload-info-box-title span {
    color: var(--blue-color-2);
}

.tax-return-upload-info-box-text {
    font: normal normal normal 14px/24px Poppins;
}

.tax-return-upload-info-width-box {
    flex: 1;
}

@media screen and (max-width: 1400px) {
    .tax-return-upload-info-width-box {
        flex-basis: 100%
    }
}