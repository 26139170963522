.tax-return-payments-accordion {
    border-radius: 15px;
    text-align: center;
}

.tax-return-payments-accordion .accordion-item {
    border: 1px solid var(--blue-color-5);
    border-radius: 15px;
}

.tax-return-payments-accordion .accordion-button {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    color: var(--blue-color-2);
    border-radius: 15px !important;

}

.tax-return-payments-accordion .accordion-button:focus,
.tax-return-payments-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.tax-return-payments-accordion .accordion-button:not(.collapsed) {
    background-color: var(--white-color);
}

.tax-return-payments-accordion .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.tax-return-payments-accordion .accordion-body {
    font: normal normal normal 14px/22px Poppins;
    padding-top: 0;
}

.tax-return-payments-accordion-no-draft {
    font: normal normal normal 12px/18px Poppins;
    color: var(--dark-gray-color-1);
    text-align: center;
}

.tax-return-payments-accordion-draft-y-box {
    border: 1px solid var(--yellow-color-1);
    border-radius: 10px;
    text-align: left;
}

.tax-return-payments-accordion-draft-y-title {
    font: normal normal normal 12px/18px Poppins;
    color: var(--blue-color-2);
}

.tax-return-payments-accordion-draft-y-credit-present {
    font: normal normal bold 9px/13px Poppins;
    background: var(--yellow-color-2);
    border-radius: 4px;
    color: var(--yellow-color-1);
    padding: 10px;
    width: fit-content;
}

.tax-return-payments-accordion-hr {
    color: var(--light-gray-color-1);
    opacity: 1;
    margin: 0;
}

.tax-return-payments-accordion-blue-hr {
    color: var(--blue-color-6);
    opacity: 1;
}

.tax-return-payments-accordion-file-name {
    font: normal normal normal 14px/21px Poppins;
}

.tax-return-payments-accordion-created-at {
    font: italic normal normal 9px/13px Poppins;
    color: var(--dark-gray-color-1);
}

.tax-return-payments-accordion-info-y-draft {
    font: italic normal normal 10px/14px Poppins;
    background: var(--yellow-color-2);
    border-radius: 11px;
}

.tax-return-payments-accordion-info-draft {
    font: normal normal normal 14px/22px Poppins;
    text-align: left;
}

.tax-return-payments-accordion-info-draft span {
    color: var(--blue-color-2);
    font-weight: bold;
}

.tax-return-payments-accordion-btn-title {
    font: normal normal 600 14px/21px Poppins;
}