.tax-return-section-2-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-2-children-question-info {
  font: normal normal 600 14px/22px Poppins;
}

.tax-return-section-2-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-2-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}