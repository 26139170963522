.tax-return-progress-bar-status-accordion {
    border-radius: 15px;
}

.tax-return-progress-bar-status-accordion .accordion-item {
    border: 1px solid var(--blue-color-5);
    border-radius: 15px;
}

.tax-return-progress-bar-status-accordion .accordion-button {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    color: var(--blue-color-2);
    border-radius: 15px !important;

}

.tax-return-progress-bar-status-accordion .accordion-button:focus,
.tax-return-progress-bar-status-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.tax-return-progress-bar-status-accordion .accordion-button:not(.collapsed) {
    background-color: var(--white-color);
}

.tax-return-progress-bar-status-accordion .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.tax-return-progress-bar-status-accordion .accordion-body {
    font: normal normal normal 14px/22px Poppins;
    padding-top: 0;
}

.tax-return-progress-bar-status-accordion .accordion-body .text-green {
    font-weight: 600;
    color: var(--green-color-1);
}

.tax-return-progress-bar-status-accordion .accordion-body .text-blue {
    color: var(--blue-color-2);
}

.tax-return-progress-bar-status-accordion-info {
    font: italic normal normal 11px/17px Poppins;
    color: var(--dark-gray-color-3);
}

.tax-return-progress-bar-status-accordion-info span {
    color: var(--blue-color-2);
}

.tax-return-progress-bar-status-accordion-disabled .accordion-body {
    color: var(--dark-gray-color-3);
    opacity: 0.6;
}