.f24-by-qf-modal-step-2-title {
    font: normal normal 600 21px/31px Poppins;
    color: var(--blue-color-2);
}

.f24-by-qf-modal-step-2-text {
    font: normal normal normal 14px/22px Poppins;
}

.f24-by-qf-modal-step-2-border-bottom {
    border-bottom: 1px solid var(--light-gray-color-2);
}

.f24-by-qf-modal-step-2-info {
    font: italic normal normal 11px/22px Poppins;
    color: var(--dark-gray-color-3);
}

.f24-by-qf-modal-step-2-confirm-box {
    background: var(--blue-color-10);
    border-radius: 10px;
    text-align: center;
}

.f24-by-qf-modal-step-2-confirm-title {
    font: normal normal bold 14px/22px Poppins;
    color: var(--blue-color-2);
}

.f24-by-qf-modal-step-2-confirm-text {
    font: normal normal normal 14px/22px Poppins;
}