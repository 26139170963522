.tax-return-section-12-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-12-sub-title {
  font: normal normal 600 15px/22px Poppins;
}

.tax-return-section-12-info-text {
  font: italic normal normal 12px/22px Poppins;
  color: var(--dark-gray-color-2);
  font-style: italic;
}

.tax-return-section-12-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-12-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}

.tax-return-section-12-container-text {
  background-color: var(--blue-color-10);
  border-radius: 10px;
  opacity: 1;
}

.tax-return-section-12-container-title {
  font: normal normal 600 14px/22px Poppins;
  letter-spacing: 0px;
  color: var(--blue-color-2);
}

.tax-return-section-12-container-description {
  font: normal normal normal 14px/22px Poppins;
}