.ddr-preview-draft-documents-modal-title {
    font: normal normal 600 16px/22px Poppins;
    color: var(--blue-color-2);
}

.ddr-preview-draft-documents-modal-title span {
    color: var(--black-color);
}

.ddr-preview-draft-documents-modal-upload-date {
    font: italic normal normal 9px/13px Poppins;
    color: var(--dark-gray-color-1);
}

.ddr-preview-draft-documents-modal-note-payments {
    max-height: 600px;
    overflow: auto;
    background-color: var(--blue-color-10);
    font: normal normal normal 14px/21px Poppins;
    border-radius: 8spx;
}

.ddr-preview-draft-documents-modal-note-payments::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 10px;
}

.ddr-preview-draft-documents-modal-note-payments::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.ddr-preview-draft-documents-modal-render-file {
    height: 550px;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.ddr-preview-draft-documents-modal-render-file .react-pdf__Page__canvas {
    margin: 0 auto;
}

.ddr-preview-draft-documents-modal-render-file::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
    height: 8px;
}

.ddr-preview-draft-documents-modal-render-file::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}