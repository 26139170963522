.tax-return-section-recap-container {
  background: var(--white-color);
  border-radius: 17px;
}

.tax-return-section-recap-container-border {
  border: 1px solid var(--blue-color-5);
  border-radius: 10px;
}

.tax-return-section-recap-current-year {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 263px);
}

.tax-return-section-recap-title {
  font: normal normal medium Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.96px;
  color: var(--blue-color-2);
}

.tax-return-section-recap-description {
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0.14px;
}

.tax-return-section-recap-tax-year-container {
  font: normal normal medium Poppins;
  color: var(--black-color);
  background-color: var(--white-color);
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border: 1px solid var(--blue-color-4);
  border-radius: 10px;
  width: fit-content;
  z-index: 4;
  margin-top: -51px;
}

#tax-return-section-recap-ddr-years {
  padding: 10px 10px 14px 10px;
}

#tax-return-section-recap-ddr-years>div {
  font: normal normal 600 13px/27px Poppins;
  border-bottom-color: var(--white-color) !important;
  margin-left: 3px;
}

.tax-return-section-recap-tax-year-select {
  min-width: 65px;
}

.tax-return-section-recap-cancelled-box,
.tax-return-section-recap-standby-box {
  font: normal normal normal 12px/22px Poppins;
  letter-spacing: 0.14px;
  text-align: left;
  padding: 15px;
  border-radius: 10px;
}

.tax-return-section-recap-cancelled-box {
  background: var(--red-color-2);
}

.tax-return-section-recap-standby-box {
  background: var(--yellow-color-2);
}