.tax-return-receipt-accordion {
    border-radius: 15px;
}

.tax-return-receipt-accordion .accordion-item {
    border: 1px solid var(--blue-color-5);
    border-radius: 15px;
}

.tax-return-receipt-accordion .accordion-button {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    color: var(--blue-color-2);
    border-radius: 15px !important;

}

.tax-return-receipt-accordion .accordion-button:focus,
.tax-return-receipt-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.tax-return-receipt-accordion .accordion-button:not(.collapsed) {
    background-color: var(--white-color);
}

.tax-return-receipt-accordion .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.tax-return-receipt-accordion .accordion-body {
    font: normal normal normal 14px/22px Poppins;
    padding-top: 0;
}

.tax-return-receipt-list-file-box {
    background-color: var(--blue-color-10);
    border-radius: 10px;
    padding: 1rem;
}

.tax-return-receipt-list-file-name {
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--black-color);
    line-break: anywhere;
    text-align: start;
}

.tax-return-receipt-list-file-name div {
    font: normal normal normal 12px/18px Poppins;
    color: var(--dark-gray-color-2);
    line-break: anywhere;
    text-align: start;
}

.tax-return-receipt-list-file-list-container {
    height: 190px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.tax-return-receipt-list-file-list-container::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 6px;
}

.tax-return-receipt-list-file-list-container::-webkit-scrollbar-thumb {
    background: var(--blue-color-8);
    border-radius: 8px;
}


.tax-return-receipt-list-no-file {
    font: normal normal normal 14px/22px Poppins;
    color: var(--dark-gray-color-3);
    text-align: center;
}

.tax-return-receipt-file-name {
    font: normal normal normal 13px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--blue-color-1);
    line-break: anywhere;
}

.tax-return-receipt-file-list-info {
    font: italic normal normal 9px/13px Poppins;
    color: var(--dark-gray-color-1);
}