.tax-return-section-3-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-3-question {
  font: normal normal 600 14px/22px Poppins;
  letter-spacing: 0.14px;
  text-align: center;
}

.tax-return-section-3-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-3-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}

.tax-return-section-3-confirm-container {
  font: normal normal normal 14px/21px Poppins;
  background-color: var(--yellow-color-2);
  border-radius: 10px;
  text-align: center;
}