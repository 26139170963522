.tax-return-audio-guide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--green-color-2);
    border-radius: 7px;
    width: fit-content;
}

.tax-return-audio-guide-label {
    font: normal normal normal 11px/17px Poppins;
    color: var(--blue-color-1);
}