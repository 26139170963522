.f24-by-qf-modal-step-1-title {
    font: normal normal 600 21px/31px Poppins;
    color: var(--blue-color-2);
}

.f24-by-qf-modal-step-1-text {
    font: normal normal normal 14px/22px Poppins;
}

#f24-by-qf-modal-step-1 * {
    justify-content: center !important;
}